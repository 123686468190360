//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import { IamPermissions } from '@/modules/iam/iam-permissions';
import IamListTable from '@/modules/iam/components/iam-list-table.vue';
// import IamListFilter from '@/modules/iam/components/iam-list-filter.vue';
// import IamListToolbar from '@/modules/iam/components/iam-list-toolbar.vue';

export default {
  name: 'app-iam-list-page',

  components: {
    [IamListTable.name]: IamListTable,
    // [IamListFilter.name]: IamListFilter,
    // [IamListToolbar.name]: IamListToolbar,
  },
  computed:{
    ...mapGetters({
      // rows: 'iam/list/rows',
      // count: 'iam/list/count',
      // loading: 'iam/list/loading',
      isMobile: 'layout/isMobile',
      is_screen_xs: 'layout/is_screen_xs',
      is_screen_sm: 'layout/is_screen_sm',
      is_screen_md: 'layout/is_screen_md',
      is_screen_lg: 'layout/is_screen_lg',
      is_labtop: 'layout/is_labtop',
      currentUser: 'auth/currentUser',
      currentLanguageCode: 'layout/currentLanguageCode',
    }),
    hasPermissionToCreate() {
      return new IamPermissions(this.currentUser).create;
    },
  },
};
